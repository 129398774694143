const emojiMap = {
  0: '👍',
  1: '❤️',
  2: '😂',
  3: '😔',
  4: '🙏',
  5: '🚀',
  6: '🥳',
  7: '😠',
};

export const getEmojiByEmojiId = (emojiId) => emojiMap[emojiId] || '❓';

export const getIdByEmoji = (emoji) => {
  const entry = Object.entries(emojiMap).find(([, value]) => value === emoji);
  return entry ? parseInt(entry[0]) : null;
};

export { emojiMap };
