import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

const AppPromotionDetail = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  return (
    <div>
      <h2>App Promotion Detail</h2>
      {id ? (
        <p>Displaying details for app promotion ID: {id}</p>
      ) : (
        <p>No app promotion ID provided.</p>
      )}
    </div>
  );
};

export default AppPromotionDetail;
