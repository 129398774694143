import { FeedPostLabelEnum } from "@/enums/feed";
import { VideoFile } from "../files/videoFile";
import { GifMedia } from "../files/gifMedia";
import { DataFile } from "../files/dataFile";
import { VoiceFile } from "../files/voiceFile";
import { Reaction } from "./reaction";
import { AppUser } from "../user/appUser";

class FeedPost {
    constructor({
        id,
        title,
        authorId,
        label = FeedPostLabelEnum.ALLGEMEIN,
        commentCount = 0,
        sharing = false,
        imageMedia = [],
        videoMedia = [],
        gifMedia = [],
        fileMedia = [],
        voiceMedia = [],
        comments = [],
        reactions = [],
        textContent,
        created,
        updated = null,
        user,
    }) {
        this.id = id;
        this.title = title;
        this.authorId = authorId;
        this.label = label;
        this.commentCount = commentCount;
        this.sharing = sharing;
        this.imageMedia = this.validateStringArray(imageMedia, 'Image Media');
        this.videoMedia = this.validateMediaArray(videoMedia, VideoFile, 'Video Media');
        this.gifMedia = this.validateMediaArray(gifMedia, GifMedia, 'Gif Media');
        this.fileMedia = this.validateMediaArray(fileMedia, DataFile, 'File Media');
        this.voiceMedia = this.validateMediaArray(voiceMedia, VoiceFile, 'Voice Media');
        this.comments = comments;
        this.reactions = this.validateReactions(reactions);
        this.textContent = textContent;
        this.created = created ? new Date(created.milliseconds) : null;
        this.updated = updated ? new Date(updated) : null;
        this.user = user instanceof AppUser ? user : new AppUser(user);
    }

    validateStringArray(array, mediaType) {
        if (!Array.isArray(array)) {
            throw new Error(`${mediaType} must be an array.`);
        }

        if (array.length > 0 && !array.every(item => typeof item === 'string')) {
            throw new Error(`${mediaType} must contain only strings (URLs).`);
        }

        return array;
    }

    validateMediaArray(mediaArray, mediaClass, mediaType) {
        if (!Array.isArray(mediaArray)) {
            throw new Error(`${mediaType} must be an array.`);
        }

        if (mediaArray.length > 0 && !mediaArray.every(item => item instanceof mediaClass)) {
            throw new Error(`${mediaType} must contain only instances of ${mediaClass.name}.`);
        }

        return mediaArray;
    }

    validateReactions(reactions) {
        if (!Array.isArray(reactions)) {
            throw new Error("Reactions must be an array.");
        }
        return reactions.map(reaction => new Reaction(reaction));
    }

    static fromJson(json) {
        return new FeedPost({
            id: json.id,
            title: json.title,
            authorId: json.authorId,
            label: json.label,
            commentCount: json.commentCount,
            sharing: json.sharing,
            imageMedia: json.imageMedia,
            videoMedia: json.videoMedia.map(item => new VideoFile(item)),
            gifMedia: json.gifMedia.map(item => new GifMedia(item)),
            fileMedia: json.fileMedia.map(item => new DataFile(item)),
            voiceMedia: json.voiceMedia.map(item => new VoiceFile(item)),
            comments: json.comments,
            reactions: json.reactions ? json.reactions.map(reaction => Reaction.fromJson(reaction)) : [],
            textContent: json.textContent,
            created: json.created ? { milliseconds: json.created.milliseconds } : null,
            updated: json.updated ? new Date(json.updated) : null,
            user: new AppUser(json.user),
        });
    }

    toJson() {
        return {
            id: this.id,
            title: this.title,
            authorId: this.authorId,
            label: this.label,
            commentCount: this.commentCount,
            sharing: this.sharing,
            imageMedia: this.imageMedia,
            videoMedia: this.videoMedia.map(item => item.toJson()),
            gifMedia: this.gifMedia.map(item => item.toJson()),
            fileMedia: this.fileMedia.map(item => item.toJson()),
            voiceMedia: this.voiceMedia.map(item => item.toJson()),
            comments: this.comments,
            reactions: this.reactions.map(reaction => reaction.toJson()),
            textContent: this.textContent,
            created: this.created ? { milliseconds: this.created.getTime() } : null,
            updated: this.updated ? this.updated.toISOString() : null,
            user: this.user.toJson(),
        };
    }
}

export { FeedPost };