class Wiki {
    constructor({
        id,
        title,
        publicUpload,
        created,
        updated,
        img,
        description,
        department,
        documentCount
    }) {
        this.id = id;
        this.title = title;
        this.publicUpload = publicUpload;
        this.created = created;
        this.updated = updated;
        this.img = img;
        this.description = description;
        this.department = department;
        this.documentCount = documentCount;
    }

    static fromJson(json) {
        return new Wiki(json);
    }
}

module.exports = Wiki;
