"use client";

import React, { useState, useEffect } from 'react';
import { Button, Input, Checkbox, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Image, Spacer, } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from 'react-router-dom';
import authController from "../api/authController";

export default function AuthPage() {
    const location = useLocation();
    const [isVisible, setIsVisible] = React.useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaveUsername, setIsSaveUsername] = React.useState(false);
    const [usernameError, setUsernameError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { isOpen: isErrorModalOpen, onOpen: onErrorModalOpen, onClose: onErrorModalClose } = useDisclosure();
    const redirectUrl = new URLSearchParams(location.search).get('redirect') || '/';

    useEffect(() => {
        const rememberedUsername = authController.presetUsernameIfRemembered();
        setUsername(rememberedUsername);
        if (rememberedUsername !== '' && rememberedUsername !== null) {
            setIsSaveUsername(true);
        }
    }, []);
    const keyHandleLogin = (event) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    }
    const handleLogin = () => {
        setIsLoading(true);
        setUsernameError(false);
        setPasswordError(false);

        if (!username.trim()) {
            setUsernameError(true);
            setIsLoading(false);
            return;
        }
        if (!password.trim()) {
            setPasswordError(true);
            setIsLoading(false);
            return;
        }

        authController.loginUserApi(username, password, isSaveUsername).then(({ localUser, error }) => {
            setIsLoading(false);
            if (error) {
                onErrorModalOpen();
            } else {
                navigate(redirectUrl);
            }
        });
    };

    return (
        <>
            <div className="relative flex h-screen w-screen">
                <div className="absolute left-2 top-5 lg:left-5">
                    <div className="flex items-center">
                        <Image
                            height={20}
                            alt="ZOXSY ZZ"
                            src="https://www.sx-oz.de/assets/img/logos/ZZ-Logo_ZOXSY.svg"
                        />
                        <Spacer x={2} />
                        <p className="font-medium">InTeam</p>
                    </div>
                </div>

                <div className="flex w-full items-center justify-center bg-background lg:w-1/2">
                    <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
                        <div className="w-full text-left">
                            <p className="pb-2 text-xl font-medium">Hallo 👋</p>
                            <p className="text-small text-default-500">Schön, dass Du da bist. Logge Dich einfach mit Deinen ZZ-Zugangsdaten ein.</p>
                        </div>

                        <form className="flex w-full flex-col gap-3" onSubmit={(e) => e.preventDefault()}>
                            <Input
                                label="Benutzername"
                                value={username}
                                isInvalid={usernameError}
                                name="email"
                                placeholder=""
                                type="text"
                                variant="bordered"
                                onChange={(e) => {
                                    setUsername(e.target.value.replace(/\s/g, ''));
                                    setUsernameError(false)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === ' ' || e.key === 'Spacebar') {
                                        e.preventDefault();
                                    }
                                }}
                            />

                            <Input
                                endContent={
                                    <button type="button" onClick={toggleVisibility}>
                                        {isVisible ? (
                                            <Icon
                                                className="pointer-events-none text-2xl text-default-400"
                                                icon="solar:eye-closed-linear"
                                            />
                                        ) : (
                                            <Icon
                                                className="pointer-events-none text-2xl text-default-400"
                                                icon="solar:eye-bold"
                                            />
                                        )}
                                    </button>
                                }
                                label="Passwort"
                                name="password"
                                placeholder=""
                                type={isVisible ? "text" : "password"}
                                isInvalid={passwordError}
                                variant="bordered"
                                onKeyUp={event => keyHandleLogin(event)}
                                onChange={(e) => {
                                    setPassword(e.target.value.replace(/\s/g, ''));
                                    setPasswordError(false);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === ' ' || e.key === 'Spacebar') {
                                        e.preventDefault();
                                    }
                                }}
                            />

                            <div className="flex items-center justify-between px-1 py-2">
                                <Checkbox name="remember" size="sm" isSelected={isSaveUsername} onValueChange={setIsSaveUsername}>
                                    Benutzernamen speichern
                                </Checkbox>
                            </div>
                            <Button color="primary" onPress={handleLogin} isLoading={isLoading}>
                                Einloggen
                            </Button>
                        </form>
                    </div>
                </div>

                <div
                    className="relative hidden w-1/2 flex-col-reverse rounded-medium p-10 shadow-small lg:flex"
                    style={{
                        backgroundImage:
                            "url(https://www.zoxs.org/wp-content/uploads/2021/06/ZOXS-Hauptgebaeude-Wesel.jpg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                </div>
            </div>
            <Modal backdrop="blur" isOpen={isErrorModalOpen} onClose={onErrorModalClose} placement="bottom-center">
                <ModalContent>
                    {(onErrorModalClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Login fehlgeschlagen</ModalHeader>
                            <ModalBody>
                                <p>
                                    Prüfe Deinen Zugang oder Deine Berechtigungen!
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onErrorModalClose}>
                                    Alles klar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}

export { };