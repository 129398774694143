"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  User,
  Badge,
  Avatar,
  Button,
  ScrollShadow,
  Card,
  CardBody,
  CardFooter,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Spacer,
  Image,
  useDisclosure,
  Kbd,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import NotificationsCard from "./notifications-card";
import Sidebar from "./sidebar";
import authController from "../../api/authController";
import useToast from "../../hooks/useToast";
import SidebarDrawer from "./sidebar-drawer";
import { useLocation, useNavigate } from "react-router-dom";
import Feed from "../feed/Feed";
import Food from "../Food";
import People from "../People";
import Wiki from "../Wiki";
import Settings from "../settings/Settings";
import SickReport from "../SickReport";
import Chat from "../Chat";
import Todo from "../Todo";
import Calendar from "../Calendar";
import Photo from "../Photo";
import Department from "../../enums/department";
import Location from "../../enums/location";
import AppDownloadContent from "../../components/AppDownloadContent";
import FeedCreate from "../feed/FeedCreate";
import userService from "@/services/userService";
import wallpaper from '../../assets/images/wallpaper.png';
import notificationService from "@/services/notificationService";
import AppPromotionsPage from "../app-promotions/Page";
import AppFeedPage from "../app-feed/Page";
import AppPushPage from "../app-push/Page";

export default function AppHome() {
  const navigate = useNavigate();
  const location = useLocation();

  const [unreadCount, setUnreadCount] = useState(0);
  useEffect(() => {
    notificationService.subscribeToNotifications();

    const unsubscribe = notificationService.addListener(setUnreadCount);

    return () => {
      unsubscribe();
      notificationService.unsubscribe();
    };
  }, []);

  const handleOpenFeedCreate = useCallback(() => {
    setIsFeedCreateOpen(true);
    navigate('/feed/create');
  }, [navigate]);

  const handleCloseFeedCreate = useCallback(() => {
    setIsFeedCreateOpen(false);
    navigate('/feed');
  }, [navigate]);

  const sidebarItems = useMemo(() => {
    const mainItems = [
      {
        key: "feed",
        icon: "solar:home-2-linear",
        title: "Feed",
        component: "Feed",
        endContent: (
          <Icon className="text-default-400" icon="solar:add-circle-line-duotone" width={24} onClick={handleOpenFeedCreate} />
        ),
      },
      {
        key: "food",
        icon: "solar:chef-hat-heart-linear",
        title: "Mittagstisch",
        component: "Food",
      },
      {
        key: "chats",
        icon: "solar:chat-round-line-linear",
        title: "Chats",
        component: "Chat",
        endContent: (
          <Icon className="text-default-400" icon="solar:add-circle-line-duotone" width={24} />
        ),
      },
      {
        key: "todo",
        icon: "solar:checklist-minimalistic-linear",
        title: "ToDo",
        component: "Todo",
        endContent: (
          <Icon className="text-default-400" icon="solar:add-circle-line-duotone" width={24} />
        ),
      },
      {
        key: "team",
        icon: "solar:users-group-two-rounded-linear",
        title: "Team",
        component: "People",
      },
      {
        key: "wiki",
        icon: "solar:notebook-bookmark-linear",
        title: "Wiki",
        component: "Wiki",
      },
      {
        key: "calendar",
        icon: "solar:calendar-linear",
        title: "Kalender",
        component: "Calendar",
      },
      {
        key: "photo",
        icon: "solar:gallery-wide-linear",
        title: "Fotoalbum",
        component: "Photo",
      },
      {
        key: "sick",
        icon: "solar:bacteria-linear",
        title: "Krankmeldung",
        component: "SickReport",
      },
      {
        key: "settings",
        icon: "solar:settings-outline",
        title: "Einstellungen",
        component: "Settings",
      },
    ];

    const adminItems = [];

    if (userService.user.modules.includes(1)) {
      adminItems.push({
        key: "admin-article",
        icon: "solar:clipboard-text-linear",
        title: "Artikelaufruf",
        component: "Article",
      });
    }

    if (userService.user.modules.includes(2)) {
      adminItems.push({
        key: "admin-articlerequests",
        icon: "solar:chat-round-money-outline",
        title: "Ankaufanfragen",
        component: "ArticleRequests",
      });
    }

    if (userService.user.modules.includes(3)) {
      adminItems.push({
        key: "admin-promotion",
        icon: "solar:gallery-wide-linear",
        title: "App-Promotions",
        component: "AppPromotionsPage",
      });
    }

    if (userService.user.modules.includes(4)) {
      adminItems.push({
        key: "admin-feed",
        icon: "solar:feed-outline",
        title: "App-Feed",
        component: "AppFeedPage",
      });
    }

    if (userService.user.modules.includes(8)) {
      adminItems.push({
        key: "admin-push",
        icon: "solar:notification-unread-lines-outline",
        title: "App-Pushnachrichten",
        component: "AppPushPage",
      });
    }

    if (userService.user.modules.includes(5)) {
      adminItems.push({
        key: "admin-feedack",
        icon: "solar:user-speak-rounded-outline",
        title: "App-Feedback",
        component: "AppFeedback",
      });
    }

    if (userService.user.modules.includes(9) || userService.user.modules.includes(10)) {
      adminItems.push({
        key: "admin-wawi",
        icon: "solar:bag-smile-outline",
        title: "WaWi Aufträge",
        component: "WaWi",
      });
    }

    if (userService.user.modules.includes(11)) {
      adminItems.push({
        key: "admin-customer",
        icon: "solar:user-id-outline",
        title: "Kundendaten",
        component: "Customer",
      });
    }

    const sections = [
      {
        key: "main",
        title: "",
        items: mainItems,
      },
    ];

    if (adminItems.length > 0) {
      sections.push({
        key: "management",
        title: "ZOXS Verwaltung",
        items: adminItems,
      });
    }

    return sections;
  }, [handleOpenFeedCreate]);

  const [currentModule, setCurrentModule] = useState('feed');
  const [selectedKey, setSelectedKey] = useState('feed');
  const [isFeedCreateOpen, setIsFeedCreateOpen] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { showToast } = useToast();
  const { isOpen: isModOpen, onOpen: onModOpen, onOpenChange: onModOpenChange } = useDisclosure();

  useEffect(() => {
    if (location.pathname === '/feed/create') {
      setIsFeedCreateOpen(true);
    } else {
      setIsFeedCreateOpen(false);
    }

    const moduleFromPath = decodeURIComponent(location.pathname.split('/')[1].toLowerCase());
    const matchedItem = sidebarItems.reduce((acc, item) => {
      acc.push(item);
      if (item.items) {
        item.items.forEach(subItem => {
          acc.push(subItem);
        });
      }
      return acc;
    }, []).find(item => item.key.toLowerCase() === moduleFromPath);


    const foundItem = sidebarItems.find(item => item.key === moduleFromPath);

    if (matchedItem) {
      setCurrentModule(matchedItem.component);
      setSelectedKey(matchedItem.key);
    } else {
      setCurrentModule("feed");
      setSelectedKey("feed");
    }

  }, [location, sidebarItems]);

  const handleRefreshUser = () => {
    userService.refreshUser().then((response) => {
      if (response.user) {
        showToast("Aktualisierung erfolgreich!", "success");
      } else {
        showToast(response.error || "Fehler bei der Aktualisierung.", "error");
      }
    });
  };

  const logout = () => {
    authController.logoutUser();
    navigate("/auth");
    showToast("Logout erfolgreich!", "success");
  };

  const handleSetCurrentModule = (moduleKey) => {
    const matchedItem = sidebarItems.reduce((acc, item) => {
      acc.push(item);
      if (item.items) {
        item.items.forEach(subItem => {
          acc.push(subItem);
        });
      }
      return acc;
    }, []).find(item => item.key === moduleKey);
    if (matchedItem) {
      navigate(`/${encodeURIComponent(matchedItem.key)}`);
      setCurrentModule(matchedItem.component);
    } else {
      navigate('/');
      setCurrentModule("feed");
    }
  };

  const componentsMap = {
    Feed: Feed,
    FeedCreate: FeedCreate,
    Food: Food,
    Chat: Chat,
    Todo: Todo,
    People: People,
    Wiki: Wiki,
    Calendar: Calendar,
    Photo: Photo,
    SickReport: SickReport,
    Settings: Settings,
    AppPromotionsPage: AppPromotionsPage,
    AppFeedPage: AppFeedPage,
    AppPushPage: AppPushPage,
    Demo: () => <div>Demo Component</div>,
  };

  const renderModule = () => {
    if (isFeedCreateOpen) {
      return (
        <div style={{ position: "relative", opacity: transitioning ? 0 : 1, transition: "opacity 0.5s ease-in-out" }}>
          <FeedCreate closeFeedCreate={handleCloseFeedCreate} />
        </div>
      );
    }

    const ModuleComponent = componentsMap[currentModule] || componentsMap.Feed;

    return (
      <div
        style={{
          position: "relative",
          opacity: transitioning ? 0 : 1,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        <ModuleComponent isAtBottom={isAtBottom} />
      </div>
    );
  };

  const isSelected = (key) => {
    return sidebarItems.find(item => item.key === key)?.component === currentModule;
  };

  const content = (
    <div className="relative flex h-full w-72 flex-col p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 px-2">
          <div className="flex h-8 w-8 items-center justify-center rounded-full">
            <Image
              height={20}
              alt="ZOXSY ZZ"
              src="https://www.sx-oz.de/assets/img/logos/ZZ-Logo_ZOXSY.svg"
            />
          </div>
          <span className="font-medium">InTeam</span>
        </div>
        <div className="flex items-center justify-end">
          <Dropdown showArrow placement="bottom-start">
            <DropdownTrigger>
              <Button disableRipple isIconOnly className="-mr-1" radius="full" variant="light">
                <Avatar className="h-6 w-6 cursor-pointer" name={`${userService.user.firstname} ${userService.user.surname}`} src={userService.user.avatarUrl} />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Custom item styles" disabledKeys={["profile"]}>
              <DropdownSection showDivider aria-label="Profile & Actions">
                <DropdownItem
                  key="profile"
                  isReadOnly
                  className="h-14 gap-2 opacity-100"
                  textValue="Signed in as"
                >
                  <User
                    avatarProps={{ size: "sm", src: userService.user.avatarUrl, imgProps: { className: "transition-none" } }}
                    classNames={{ name: "text-default-600", description: "text-default-500" }}
                    description={`${userService.user.department && Department[userService.user.department]
                      ? Department[userService.user.department].departmentName
                      : 'Unbekannte Abteilung'}, ${userService.user.location && Location[userService.user.location]
                        ? Location[userService.user.location].locationName
                        : 'Unbekannter Ort'
                      }`}
                    name={`${userService.user.firstname} ${userService.user.surname}`}
                  />
                </DropdownItem>
                <DropdownItem
                  onClick={handleRefreshUser}
                  description="User updaten"
                  startContent={<Icon className="text-default-500" icon="solar:refresh-linear" width={18} />}
                >
                  Aktualisieren
                </DropdownItem>
              </DropdownSection>

              <DropdownSection aria-label="Logout">
                <DropdownItem
                  onClick={logout}
                  description="Schönen Feierabend"
                  startContent={<Icon className="text-default-500" icon="solar:logout-3-linear" width={18} />}
                >
                  Logout
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
          <Popover offset={12} placement="bottom-start">
            <PopoverTrigger>
              <Button disableRipple isIconOnly className="overflow-visible" radius="full" variant="light">
                <Badge color="primary" content={unreadCount} showOutline={false} size="md" isInvisible={unreadCount === 0}>
                  <Icon className="text-default-500" icon="solar:bell-linear" width={22} />
                </Badge>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="max-w-[90vw] p-0 sm:max-w-[380px]">
              <NotificationsCard className="w-full shadow-none" />
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <Spacer y={8} />

      <div className="flex flex-col gap-y-2">
        <Input
          fullWidth
          aria-label="search"
          labelPlacement="outside"
          placeholder='Suche...'
          variant="bordered"
          endContent={<Kbd keys={["command"]}>K</Kbd>}
          startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="solar:magnifer-linear" width={18} />}
        />
      </div>

      <ScrollShadow className="-mr-6 h-full max-h-full py-6 pr-6">
        <Sidebar
          items={sidebarItems}
          defaultSelectedKey={selectedKey}
          onSelect={handleSetCurrentModule}
        />
        <Spacer y={8} />
        <Card className="mx-2 overflow-visible" shadow="sm">
          <CardBody className="items-center py-5 text-center">
            <h3 className="text-medium font-medium text-default-700">
              InTeam
              <span aria-label="phone-emoji" className="ml-2" role="img">
                📱
              </span>
            </h3>
            <p className="p-4 text-small text-default-500">
              Lade Dir jetzt die ZZ-Newsapp auf Dein Smartphone herunter.
            </p>
          </CardBody>
          <CardFooter className="absolute -bottom-8 justify-center">
            <Button className="px-10" color="primary" radius="full" onPress={onModOpen}>
              zum Download
            </Button>
          </CardFooter>
        </Card>
      </ScrollShadow>
    </div>
  );

  const mainRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (mainRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = mainRef.current;

        const offset = 5;
        const isBottom = scrollHeight - scrollTop - clientHeight < offset;

        setIsAtBottom(isBottom);
      }
    };

    const mainElement = mainRef.current;
    if (mainElement) {
      mainElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainElement) {
        mainElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      <div className="flex h-dvh w-full">
        <SidebarDrawer
          className="flex-none border-r-small border-divider"
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        >
          {content}
        </SidebarDrawer>
        <div className="relative flex w-full flex-col gap-y-4 sm:max-w-[calc(100%_-_288px)]">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${wallpaper})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              opacity: 0.2,
            }}
          ></div>
          <header className="flex h-16 min-h-16 items-center justify-between gap-2 overflow-x-scroll rounded-medium px-4 py-2 sm:hidden">
            <div className="flex max-w-full items-center gap-2">
              <Button isIconOnly className="flex" size="sm" variant="light" onPress={onOpen}>
                <Icon className="text-default-500" height={24} icon="solar:hamburger-menu-outline" width={24} />
              </Button>
              <h2 className="truncate text-medium font-medium text-default-700">Header-Navi</h2>
            </div>
            <div></div>
          </header>
          <main ref={mainRef} className="flex-grow w-full overflow-auto">
            {renderModule()}
          </main>
        </div>
      </div>
      <Modal
        isOpen={isModOpen}
        onOpenChange={onModOpenChange}
      >
        <ModalContent>
          {(onModClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">App-Download</ModalHeader>
              <ModalBody>
                <AppDownloadContent />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
