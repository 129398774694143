"use client";

import React from "react";

export default function Todo() {

  return (
    <div className="w-full flex-1 p-4">
      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">ToDo</h1>
      </div>
      <h2 className="mt-2 text-small text-default-500 mb-6">
        Dein persönlicher Space - niemand kann ihn sehen.
      </h2>
    </div>
  );
}