import { doc, collection, onSnapshot, updateDoc } from "firebase/firestore";
import db from "@/firebaseConfig";
import userService from "@/services/userService";

const notificationService = {
  notifications: [],
  unreadCount: 0,
  listeners: [],
  unsubscribe: null,

  subscribeToNotifications(callback) {
    const userId = userService.user.id.toString();
    const userDoc = doc(db, "users", userId);
    const notificationsCollection = collection(userDoc, "notifications");
  
    this.unsubscribe = onSnapshot(notificationsCollection, (snapshot) => {
      this.notifications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
  
      this.unreadCount = this.notifications.filter((n) => !n.read).length;
  
      this.listeners.forEach((listener) => listener(this.unreadCount));
  
      if (callback) {
        callback(this.notifications);
      }
    });
  
    return this.unsubscribe;
  },  

  unsubscribeNotifications() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  },

  markAsRead: async function (id) {
    const userId = userService.user.id.toString();
    const notificationDoc = doc(db, "users", userId, "notifications", id);
    await updateDoc(notificationDoc, { read: true });
  },
  
  markAllAsRead: async function () {
    const userId = userService.user.id.toString();
    const promises = this.notifications
      .filter((notification) => !notification.read)
      .map((notification) => {
        const notificationDoc = doc(db, "users", userId, "notifications", notification.id);
        return updateDoc(notificationDoc, { read: true });
      });

    await Promise.all(promises);
    this.unreadCount = 0;
    this.listeners.forEach((listener) => listener(this.unreadCount));
  },

  addListener(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  },
};

export default notificationService;