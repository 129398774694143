import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import CommentSection from './CommentSection';
import PostDetail from './PostDetail';

const PostComponent = ({ post, updateCommentCount }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <PostDetail post={post} onOpenPopup={onOpen} shadow='sm' />

            <Modal backdrop='blur' size='5xl' isOpen={isOpen} onClose={onClose} scrollBehavior="inside" radius='lg' className="overflow-hidden">
                <ModalContent>
                    {(onClose) => (
                        <div className="flex">
                            <div className="w-full md:w-1/2 md:pr-2 md:mb-0 overflow-hidden">
                                <div className="overflow-y-auto min-h-[100px] max-h-[70vh]">
                                    <PostDetail post={post} onOpenPopup={onOpen} shadow='none' />
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 md:pr-2 md:mb-0 overflow-hidden">
                                <div className="min-h-[100px] max-h-[70vh]">
                                    <CommentSection id={post.id} updateCommentCount={updateCommentCount}/>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default PostComponent;