import React, { useEffect, useState, useMemo } from 'react';
import {
    Card, CardFooter, Image, Button, Spinner, Modal,
    ModalContent, ModalHeader, useDisclosure, ModalFooter,
    ModalBody, Input, Spacer, Autocomplete, AutocompleteItem, Breadcrumbs, BreadcrumbItem
} from '@nextui-org/react';
import wikiService from '../services/wikiService';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import userService from '../services/userService';
import { v4 as uuidv4 } from 'uuid';
import markdownToDelta from 'markdown-to-quill-delta';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';

const Wiki = () => {
    const [wikis, setWikis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedWiki, setSelectedWiki] = useState(null);
    const [editorContent, setEditorContent] = useState('');
    const [title, setTitle] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const wikiId = pathSegments[2] || null;

    const editorOptions = useMemo(() => {
        return {
            autofocus: true,
            spellChecker: false,
            status: false,
            placeholder: 'Fange an zu tippen...',
            toolbar: [
                "bold", "italic", "heading", "|",
                "code", "unordered-list", "ordered-list", "|",
                "link", "|",
                "guide"
            ],
            shortcuts: {
                togglePreview: null,
                toggleFullScreen: null,
                toggleSideBySide: null,
            },
        };
    }, []);

    useEffect(() => {
        const fetchWikis = async () => {
            try {
                const { wikiData } = await wikiService.getWikiCollections();
                setWikis(wikiData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWikis();
    }, []);

    const handlePost = async () => {
        if (title.length < 5) {
            alert('Titel muss mindestens 5 Zeichen lang sein.');
            return;
        }

        if (!selectedWiki) {
            alert('Bitte eine Kategorie auswählen.');
            return;
        }

        const quillContent = markdownToDelta(editorContent);
        const newWiki = {
            id: uuidv4(),
            author: userService.getUser().id,
            created: new Date().toISOString(),
            title,
            category: selectedWiki,
            markdownContent: editorContent,
            quillContent,
        };

        console.log('Posting new wiki:', newWiki);
        onClose();
    };

    const handleWikiClick = (wiki) => {
        navigate(`/wiki/${wiki.id}`);
        setSelectedWiki(wiki);
    };

    const handleBreadcrumbClick = () => {
        navigate(`/wiki`);
        setSelectedWiki(null);
    };

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const wikiItems = wikis.map((wiki) => ({ value: wiki.id, label: wiki.title }));

    const selectedWikiData = wikis.find(wiki => wiki.id === wikiId);

    return (
        <>
            <div className="w-full flex-1 p-4">
                <div className="flex items-center gap-x-3">
                    <h1 className="text-3xl font-bold leading-9 text-default-foreground">Wiki</h1>
                </div>
                <h2 className="mt-2 text-small text-default-500 mb-6">
                    {'Deine Knowledge-Base für Deinen Alltag im ZOXS-Universum.'}
                </h2>

                {wikiId ? (
                    selectedWikiData ? (
                        <>
                            <Breadcrumbs>
                                <BreadcrumbItem onPress={handleBreadcrumbClick}>Wiki</BreadcrumbItem>
                                <BreadcrumbItem>{selectedWikiData.title}</BreadcrumbItem>
                            </Breadcrumbs>

                            <div className="mt-6">
                                <h3 className="text-xl font-semibold text-default-500">{selectedWikiData.title}</h3>

                            </div>
                        </>
                    ) : (
                        <div className="mt-6">
                            <h3 className="text-xl font-semibold text-default-500">Ups</h3>
                            <p>Dieses Wiki gibt es nicht.</p>
                        </div>
                    )
                ) : (
                    <div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4">
                        {wikis.map((wiki, index) => (
                            <div key={index} className="flex justify-center">
                                <Card isFooterBlurred className="w-full h-[300px]" isPressable onPress={() => handleWikiClick(wiki)}>
                                    <Image
                                        removeWrapper
                                        alt={wiki.title}
                                        className="z-0 w-full h-full object-cover"
                                        src={wiki.img}
                                    />
                                    <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                                        <div className="flex flex-grow gap-2 items-center">
                                            <div className="flex flex-col">
                                                <p className="text-white/90 font-medium text-l">{wiki.title}</p>
                                            </div>
                                        </div>
                                        <Button color="primary" radius="full" size="sm" onPress={() => handleWikiClick(wiki)}>Aufrufen</Button>
                                    </CardFooter>
                                </Card>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Modal backdrop='blur' size='5xl' isOpen={isOpen} onClose={onClose} scrollBehavior="inside" radius='lg' isDismissable={false}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="center">Wikibeitrag anlegen</ModalHeader>
                            <ModalBody>
                                <div className="flex justify-between">
                                    <Input
                                        size='md'
                                        type="text"
                                        label="Titel"
                                        variant='bordered'
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        labelPlacement="inside"
                                        startContent={
                                            <i className="fa-light fa-icons text-default-400 pointer-events-none"></i>
                                        }
                                    />
                                    <Spacer x={2} />
                                    <Autocomplete
                                        label="Wiki-Kategorie"
                                        defaultItems={wikiItems}
                                        size='md'
                                        placeholder='Bitte auswählen'
                                        variant='bordered'
                                        selectedKey={selectedWiki}
                                        startContent={
                                            <i className="fa-light fa-layer-group text-default-400 pointer-events-none"></i>
                                        }
                                        onSelectionChange={setSelectedWiki}
                                        listboxProps={{
                                            emptyContent: 'Keine Kategorien.'
                                        }}
                                    >
                                        {(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
                                    </Autocomplete>
                                </div>
                                <Spacer y={2} />
                                <SimpleMDE value={editorContent} onChange={setEditorContent} options={editorOptions} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="flat" onPress={onClose}>
                                    Verwerfen
                                </Button>
                                <Button color="primary" onPress={handlePost}>
                                    Wikibeitrag posten
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <Button
                onClick={onOpen}
                iconOnly
                className="red-white-badge rounded-full"
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    height: 50,
                    width: 50,
                    minWidth: 'unset',
                }}
            >
                <Icon className="text-white" icon="solar:add-circle-linear" width={22} />
            </Button>
        </>
    );
};

export default Wiki;