"use client";

import React, { useState, } from "react";
import { Card, CardBody } from "@nextui-org/card";
import { Avatar } from "@nextui-org/avatar";
import { Icon } from "@iconify/react";
import { Button, Badge, Input, Spacer, } from "@nextui-org/react";
import { cn } from "@nextui-org/react";

const CompanySettings = React.forwardRef(({ className, ...props }, ref) => {

    const [linkedIn, setLinkedIn] = useState("");
    const [linkedInError, setLinkedInError] = useState(false);
    const [instagram, setInstagram] = useState("");
    const [instagramError, setInstagramError] = useState(false);
    const [facebook, setFacebook] = useState("");
    const [facebookError, setFacebookError] = useState(false);
    const [tiktok, setTikTok] = useState("");
    const [tiktokError, setTikTokerror] = useState(false);
    const [spotify, setSpotify] = useState("");
    const [spotifyError, setSpotifyError] = useState(false);

    return (
        <div ref={ref} className={cn("p-2", className)} {...props}>
            <div>
                <p className="text-base font-medium text-default-700">Unternehmensprofil</p>
                <p className="mt-1 text-sm font-normal text-default-400">
                    So wird Dein Unternehmen hier dargestellt.
                </p>
                <Card className="mt-4 bg-default-100" shadow="none">
                    <CardBody>
                        <div className="flex items-center gap-4">
                            <Badge
                                classNames={{
                                    badge: "w-5 h-5",
                                }}
                                content={
                                    <Button
                                        isIconOnly
                                        className="h-5 w-5 min-w-5 bg-background p-0 text-default-500"
                                        radius="full"
                                        size="sm"
                                        variant="bordered"
                                    >
                                        <Icon className="h-[9px] w-[9px]" icon="solar:pen-linear" />
                                    </Button>
                                }
                                placement="bottom-right"
                                shape="circle"
                            >
                                <Avatar className="h-16 w-16" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6BKs3GB6JxGhkBCTYes6_ulsWwcrS3EMz-g&s' />
                            </Badge>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg"
                            />
                            <div>
                                <p className="text-sm font-medium text-default-600">ZOXS GmbH</p>
                                <p className="text-xs text-default-400">
                                    Geschäftsführer: Olaf Zimmer, Thorsten Gebhardt
                                </p>
                                <p className="mt-1 text-xs text-default-400">Am Schornacker 51, 46485 Wesel</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <Spacer y={8} />
            <div>
                <p className="text-base font-medium text-default-700">Standorte</p>
                <p className="mt-1 text-sm font-normal text-default-400">Verwalte Deine Niederlassungen / Standorte hier</p>
                <Spacer y={4} />
            </div>

            <Spacer y={8} />
            <div>
                <p className="text-base font-medium text-default-700">Abteilungen</p>
                <p className="mt-1 text-sm font-normal text-default-400">Verwalte Deine Abteilungen und ihre Ansprechpartner hier</p>
                <Spacer y={4} />
            </div>


            <Spacer y={8} />
            <div>
                <p className="text-base font-medium text-default-700">Social-Media</p>
                <p className="mt-1 text-sm font-normal text-default-400">Welche Social-Media Kanäle Deiner Firma möchtest Du verlinken?</p>
                <Spacer y={4} />
                <div>
                    <Input
                        className="max-w-[500px]"
                        variant="bordered"
                        label="LinkedIn"
                        placeholder="LinkedIn Nutzername"
                        value={linkedIn}
                        startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:linkedin-01" width={18} />}
                        isInvalid={linkedInError}
                        onChange={(e) => {
                            setLinkedIn(e.target.value.replace(/\s/g, ''));
                            setLinkedInError(false);
                        }}
                    />
                    <Spacer y={2} />
                    <Input
                        className="max-w-[500px]"
                        variant="bordered"
                        label="Instagram"
                        placeholder="Instagram Nutzername"
                        value={instagram}
                        startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:instagram" width={18} />}
                        isInvalid={instagramError}
                        onChange={(e) => {
                            setInstagram(e.target.value.replace(/\s/g, ''));
                            setInstagramError(false);
                        }}
                    />
                    <Spacer y={2} />
                    <Input
                        className="max-w-[500px]"
                        variant="bordered"
                        label="TikTok"
                        placeholder="TikTok Nutzername"
                        value={tiktok}
                        startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:tiktok" width={18} />}
                        isInvalid={tiktokError}
                        onChange={(e) => {
                            setTikTok(e.target.value.replace(/\s/g, ''));
                            setTikTokerror(false);
                        }}
                    />
                    <Spacer y={2} />
                    <Input
                        className="max-w-[500px]"
                        variant="bordered"
                        label="Facebook"
                        placeholder="Facebook Nutzername"
                        value={facebook}
                        startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:facebook-01" width={18} />}
                        isInvalid={facebookError}
                        onChange={(e) => {
                            setFacebook(e.target.value.replace(/\s/g, ''));
                            setFacebookError(false);
                        }}
                    />
                    <Spacer y={2} />
                    <Input
                        className="max-w-[500px]"
                        variant="bordered"
                        label="Spotify"
                        placeholder="Spotify Nutzername"
                        value={spotify}
                        startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:spotify" width={18} />}
                        isInvalid={spotifyError}
                        onChange={(e) => {
                            setSpotify(e.target.value.replace(/\s/g, ''));
                            setSpotifyError(false);
                        }}
                    />
                </div>
            </div>
            <Button className="mt-4 bg-primary text-background" size="md">
                Speichern
            </Button>
        </div>
    );
});

CompanySettings.displayName = "CompanySettings";

export default CompanySettings;