import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Avatar, Card, CardBody, CardHeader, Spinner } from "@nextui-org/react";
import userService from '../services/userService'
import * as nmft from 'react-number-format';
import foodService from '../services/foodService';
import TimeAgo from 'react-timeago'
import germanStrings from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(germanStrings)

const OrderComponent = ({ order, authorId, offerId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [orderStatus, setOrderStatus] = useState(order.status);
    const [userImageUrl, setImageUrl] = useState(null);

    const markOrder = async () => {
        if (authorId === user.id) {
            await foodService.updateOrderStatus(offerId, order.id, "paymentConfirmed");
            setOrderStatus("paymentConfirmed");
        } else if (order.authorId === user.id) {
            await foodService.updateOrderStatus(offerId, order.id, "paid");
            setOrderStatus("paid");
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const loggedInUser = await userService.getUser();

                if (order.authorId === loggedInUser.id) {
                    setUser(loggedInUser);
                } else {
                    const fetchedUser = await userService.getUserById(order.authorId);
                    setUser(fetchedUser);
                }
            } catch (error) {
                console.error('Failed to fetch user:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchImageUrl = async () => {
            try {
                const fetchedUrl = await userService.getProfilePictureById(order.authorId);
                setImageUrl(fetchedUrl)
            } catch (error) {
                console.error('Failed to fetch image:', error);
            }
        };

        fetchUser();
        fetchImageUrl();
    }, [order.authorId]);

    return (
        <>
            <Card className="w-full">
                <CardHeader className="align-items-start">
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <div className="flex">
                            <Avatar className="m-auto" radius="full" size="md" src={userImageUrl} />
                            <div className="p-2 px-3">
                                <span className="font-bold">
                                    {user?.firstname} {user?.surname}
                                </span>
                            </div>
                            <div className="p-2 text-default-400 text-small">
                                <TimeAgo date={new Date(order.created.milliseconds)} formatter={formatter} />
                            </div>
                        </div>
                    )}
                </CardHeader>
                <CardBody className="flex-row pt-1">
                    <div>
                        {order.items.map(item => (
                            <div key={item.id}>
                                <span className="font-medium">{item.orderName}</span>
                                <span className="text-small">
                                    (
                                    <nmft.NumericFormat
                                        displayType="text"
                                        decimalSeparator=","
                                        value={item.sum}
                                        suffix=" €"
                                        fixedDecimalScale
                                        decimalScale={2}
                                    />
                                    )
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="mr-0 ml-auto">
                        {orderStatus === "paymentConfirmed" && (
                            <button
                                className="bg-green-500 text-white float-right rounded-full"
                                style={{ height: 30, width: 30 }}
                            >
                                <i className="fa-solid fa-check"></i>
                            </button>
                        )}
                        {orderStatus === "paid" && (
                            <button
                                title="Zahlung bestätigen"
                                onClick={markOrder}
                                className="bg-green-500 text-white float-right rounded-full"
                                style={{ height: 30, width: 30 }}
                            >
                                <i className="fa-solid fa-money-bill"></i>
                            </button>
                        )}
                        {orderStatus === "ordered" && (
                            <button
                                title="Als bezahlt markieren"
                                onClick={markOrder}
                                className="float-right rounded-full bg-gray-300"
                                style={{ height: 30, width: 30 }}
                            >
                                <i className="fa-solid fa-money-bill"></i>
                            </button>
                        )}
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default OrderComponent;
