class DataFile {
    constructor({ url, filename, size, filetype }) {
        this.url = url;
        this.filename = filename;
        this.size = size;
        this.filetype = filetype;
    }

    static fromJson(json) {
        return new DataFile(json);
    }

    toJson() {
        return {
            url: this.url,
            filename: this.filename,
            size: this.size,
            filetype: this.filetype,
        };
    }
}

export { DataFile };