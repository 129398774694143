import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Avatar, Spinner, } from "@nextui-org/react";
import userService from '../services/userService';
import GiphyGif from './GiphyGif';
import TimeAgo from 'react-timeago'
import germanStrings from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(germanStrings)

const CommentComponent = ({ comment }) => {
    const [user, setUser] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const fetchedUser = await userService.getUserById(comment.author);
                setUser(fetchedUser);
            } catch (error) {
                console.error('Failed to fetch user:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchImageUrl = async () => {
            try {
                const fetchedUrl = await userService.getProfilePictureById(comment.author);
                setImageUrl(fetchedUrl);
            } catch (error) {
                console.error('Failed to fetch image:', error);
            }
        };

        fetchUser();
        fetchImageUrl();
    }, [comment.author]);

    return (
        <Card className="w-full mb-4" shadow='none'>
            <CardHeader className="justify-between">
                <div className="flex gap-4">
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <Avatar isBordered radius="full" size="sm" src={imageUrl} />
                            <div className="flex flex-col gap-1 items-start justify-center">
                                <h4 className="text-small font-semibold leading-none text-default-600">{user.name}</h4>
                                <h5 className="text-small tracking-tight text-default-400"><TimeAgo
                                    date={comment.created.toDate()}
                                    formatter={formatter}
                                /></h5>
                            </div>
                        </>
                    )}
                </div>
            </CardHeader>
            <CardBody className="px-3 py-0 text-small text-default-800 mb-3">
                <p>{comment.comment}</p>
                {comment.gifMedia && comment.gifMedia.id && (
                    <div className="px-3 w-full">
                        <GiphyGif gifId={comment.gifMedia.id}></GiphyGif>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default CommentComponent;
