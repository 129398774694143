import { getEmojiByEmojiId, getIdByEmoji } from "../../helpers/emojiMap";
import { v4 as uuidv4 } from 'uuid';

class Reaction {
    constructor({
        id = uuidv4(),
        emoji,
        author,
        created,
    }) {
        this.id = id;
        this.author = author;

        if (typeof emoji === 'string') {
            this.emoji = emoji;
        } else {
            this.emoji = this.parseEmoji(emoji);
        }

        this.created = new Date(created._seconds * 1000 + created._nanoseconds / 1000000);
    }

    parseEmoji(emojiId) {
        if (typeof emojiId === 'undefined') {
            console.warn(`Invalid emoji ID: ${emojiId}, defaulting to ❓`);
            return '❓';
        }

        const emoji = getEmojiByEmojiId(emojiId);
        if (emoji === '❓') {
            console.warn(`Invalid emoji ID: ${emojiId}, using ❓`);
        }
        return emoji;
    }

    toJson() {
        return {
            id: this.id,
            emoji: typeof this.emoji === 'string' ? getIdByEmoji(this.emoji) : this.emoji,
            author: this.author,
            created: new Date(this.created._seconds * 1000 + this.created._nanoseconds / 1000000),
        };
    }

    static fromJson(json) {
        return new Reaction({
            id: json.id,
            emoji: json.emoji,
            author: json.author,
            created: json.created,
        });
    }
}

export { Reaction };
