const Department = {
    ac: { departmentName: 'Accounting', emoji: '💼' },
    cs: { departmentName: 'Customer Service', emoji: '🎧' },
    ceo: { departmentName: 'Geschäftsführung', emoji: '👔' },
    hr: { departmentName: 'Human Resources', emoji: '👥' },
    ma: { departmentName: 'Marketing', emoji: '📈' },
    qa: { departmentName: 'Quality Assurance', emoji: '🔍' },
    rf: { departmentName: 'Refurbishment', emoji: '🔧' },
    sd: { departmentName: 'Software Development', emoji: '💻' },
    wh: { departmentName: 'Warehouse', emoji: '📦' },
  };
  
  export default Department;