import React, { useState, useEffect } from 'react';
import feedService from '../services/feedService';
import CommentComponent from './Comment';
import { Spinner, Spacer, Image, Input } from "@nextui-org/react";
import emptyZoxsy from '../assets/svg/zoxsy_legal.svg'
import commentService from '../services/commentService';

function CommentSection({ id, updateCommentCount }) {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newComment, setNewComment] = useState("");

    function addComment(event) {
        event.preventDefault();
        event.stopPropagation();
        try {
            const addedComment = commentService.addComment(id, newComment);
            if (addedComment) {
                updateCommentCount(id);
                document.getElementsByClassName("newCommentForm")[0].reset();
            }
        } catch (error) {
            console.error('Could not add comment ' + event.target + ' to offer ' + id + error);
        }
    }

    const handleInput = (event) => {
        var {value} = event.target;
        if (value) {
            setNewComment(value);
        }
    }

    const fetchComments = async () => {
        try {
            const commentData = await feedService.getCommentsForPost(id);
            setComments(commentData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching comments:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchCommentsInterval = setInterval(() =>
            fetchComments(), 15000);

        fetchComments();
        return () => clearInterval(fetchCommentsInterval);
    });

    return (
        <div className="h-full overflow-hidden">
            <Spacer y={3} />
            <h4 className="text-small font-semibold leading-none text-default-600 ml-1">Kommentare</h4>
            <Spacer y={1} />
            {loading ? (
                <Spinner />
            ) : (
                <div className='h-full overflow-y-auto'>
                    <div className="min-h-[170px] comment-height">
                        {comments.length === 0 ? (
                            <div className="mt-4 flex items-center justify-center flex-col">
                                <Image
                                    width={150}
                                    alt="Keine Kommentare ZOXSY"
                                    src={emptyZoxsy}
                                />
                                <h4 className="mt-4 text-small font-semibold leading-none text-default-600">Keine Kommentare!</h4>
                                <h5 className="mt-2 mb-4 text-small tracking-tight text-default-400">
                                    Schreibe den ersten Kommentar!
                                </h5>
                            </div>
                        ) : (
                            comments.map(comment => (
                                <div className="m-1" key={comment.id}>
                                    <CommentComponent comment={comment} />
                                </div>
                            ))
                        )}
                            <form autoComplete="off" className="newCommentForm pb-3" onSubmit={addComment}>
                                <div className="flex gap-2 pb-3 mb-3 mt-2">
                                    <Input
                                        autoComplete='off'
                                        type="text"
                                        label="Was denkst Du dazu...?"
                                        name='text'
                                        variant='bordered'
                                        className="w-full"
                                        onChange={handleInput}
                                    />
                                    <button type="submit" className="p-1 mr-2">
                                        <div className="rounded-full mr-2 icon-bg-circle">
                                            <i className="fa-regular fa-paper-plane-top"></i>
                                        </div>
                                    </button>
                                </div>
                            </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CommentSection;
