import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Avatar, Button, ScrollShadow, Spinner, Tooltip } from '@nextui-org/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import peopleService from '@/services/peopleService';

function StoryList() {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const storiesContainerRef = useRef(null);

    const fetchUsers = useCallback(async () => {
        try {
            const allUsers = await peopleService.getAllUsers();
            const filteredUsers = allUsers.filter((user) => user.avatarUrl !== null);
            setUsers(filteredUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleScroll = () => {
        const { scrollLeft, offsetWidth, scrollWidth } = storiesContainerRef.current;
        const maxScrollLeft = scrollWidth - offsetWidth;
        const newStoryPage = Math.round((scrollLeft / maxScrollLeft) * (users.length / 8));
    };

    const scrollLeft = () => {
        if (storiesContainerRef.current) {
            storiesContainerRef.current.scrollBy({
                left: -200,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (storiesContainerRef.current) {
            storiesContainerRef.current.scrollBy({
                left: 200,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="relative">
            {isLoading ? (
                <div className="flex justify-center">
                    <Spinner />
                </div>
            ) : (
                <>
                    <Button
                        isIconOnly
                        color="primary"
                        size="sm"
                        aria-label="Scroll Left"
                        className="absolute top-1/4 -left-4 rounded-full p-2 z-10"
                        onPress={scrollLeft}
                    >
                        <Icon icon="solar:alt-arrow-left-linear" width={24} />
                    </Button>

                    <ScrollShadow
                        className="flex gap-1 items-center overflow-x-auto"
                        ref={storiesContainerRef}
                        onScroll={handleScroll}
                        hideScrollBar
                        orientation="horizontal"
                        offset={100}
                    >
                        {users.map((user, index) => (
                            <div
                                key={index}
                                style={{
                                    width: '55px',
                                    height: '55px',
                                    aspectRatio: '1',
                                    overflow: 'visible',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip showArrow={true} content={user.firstname}>
                                    <Avatar isBordered src={user.avatarUrl} style={{ width: '100%', height: '100%' }} />
                                </Tooltip>
                            </div>
                        ))}
                    </ScrollShadow>

                    <Button
                        isIconOnly
                        color="primary"
                        size="sm"
                        aria-label="Scroll Right"
                        className="absolute top-1/4 -right-4 rounded-full p-2 z-10"
                        onPress={scrollRight}
                    >
                        <Icon icon="solar:alt-arrow-right-linear" width={24} />
                    </Button>
                </>
            )}
        </div>
    );
}

export default StoryList;