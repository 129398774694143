import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import AppFeed from './AppFeed';
import AppFeedEditor from './AppFeedEditor';
import AppFeedDetail from './AppFeedDetail';

const AppFeedPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  const [activeComponent, setActiveComponent] = useState('AppFeed');
  const userHasPermission = userService.user.modules.includes(3);

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/admin-feed/create')) {
      setActiveComponent('AppFeedEditorCreate');
    } else if (path.startsWith('/admin-feed/edit') && id) {
      setActiveComponent('AppFeedEditorEdit');
    } else if (path.startsWith('/admin-feed/') && id) {
      setActiveComponent('AppFeedDetail');
    } else {
      setActiveComponent('AppFeed');
    }
  }, [location.pathname, id]);

  if (!userHasPermission) {
    return <NoPermission />;
  }

  return (
    <>
      {activeComponent === 'AppFeed' && <AppFeed />}
      {activeComponent === 'AppFeedEditorCreate' && <AppFeedEditor />}
      {activeComponent === 'AppFeedEditorEdit' && <AppFeedEditor id={id} />}
      {activeComponent === 'AppFeedDetail' && <AppFeedDetail id={id} />}
    </>
  );
};

export default AppFeedPage;
