import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

const AppPushDetail = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  return (
    <div>
      <h2>App Push Detail</h2>
      {id ? (
        <p>Displaying details for app push ID: {id}</p>
      ) : (
        <p>No app push ID provided.</p>
      )}
    </div>
  );
};

export default AppPushDetail;
