import "../../node_modules/video-react/dist/video-react.css";
import React from 'react';
import { Player } from 'video-react';

export const VideoPlayer = ({ videoFile }) => (
    <Player
        playsInline
        poster={videoFile.thumbnail}
        src={videoFile.url}
    />
);