import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getCookie } from "../helpers/cookieHelper";
import LocalUser from "../models/user/localUser";
import { AppUser } from "../models/user/appUser";

class UserService {
  constructor() {
    this._user = JSON.parse(localStorage.getItem('user'));
  }

  set user(user) {
    this._user = user;
    if (user !== null) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }

  get user() {
    return this._user;
  }

  setUser(user) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  isAuthenticated() {
    return this.user !== null;
  }

  async getUserById(id) {
    try {
      const authToken = getCookie('newsappuser');

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/u/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          'auth-token': authToken
        }
      });

      if (response.status === 200) {
        const userData = await response.json();
        return AppUser.fromJson(userData);
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error fetching user:", e);
    }
  }

  async getProfilePictureById(id) {
    const fallbackUrl = 'https://firebasestorage.googleapis.com/v0/b/zoxs-newsplace.appspot.com/o/ZOXSY_PLACEHOLDER.png?alt=media&token=6a14b7a0-05bd-4644-90fc-77d3c1d841df';

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `internal/users/${id}/public/${id}.jpg`);

      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error('Error fetching image URL:', error);
      return fallbackUrl;
    }
  }

  async refreshUser() {
    try {
      const authToken = getCookie('newsappuser');

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          'auth-token': authToken
        }
      });

      if (response.status === 200) {
        const userData = await response.json();
        const localUser = LocalUser.fromJson(userData);

        this.setUser(localUser);

        return { user: localUser, error: null };
      } else {
        const errorMessage = await response.text();
        return { user: null, error: errorMessage };
      }
    } catch (error) {
      console.error('Error refreshing user:', error);
      return { user: null, error: error.toString() };
    }
  }

  async updateUser(updateData) {
    try {
      const authToken = getCookie('newsappuser');

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          'auth-token': authToken
        },
        body: JSON.stringify(updateData),
      });

      if (response.status === 200) {
        const userData = await response.json();
        const localUser = LocalUser.fromJson(userData);

        this.setUser(localUser);

        return { user: localUser, error: null };
      } else {
        const errorMessage = await response.text();
        return { user: null, error: errorMessage };
      }
    } catch (error) {
      console.error('Error refreshing user:', error);
      return { user: null, error: error.toString() };
    }
  }

  async uploadProfilePicture(file) {
    try {
      const authToken = getCookie('newsappuser');

      const formData = new FormData();
      formData.append('avatar', file);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/avatar`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          'auth-token': authToken,
        },
        body: formData,
      });

      if (response.status === 200) {
        const newAvatarUrl = await response.json();
        const updatedUser = { ...this.user, avatarUrl: newAvatarUrl };
        this.setUser(updatedUser);
        return { success: true, user: this.user };
      } else {
        const errorMessage = await response.text();
        return { success: false, message: errorMessage };
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      return { success: false, message: error.toString() };
    }
  }
}

const userService = new UserService();

export default userService;