import useToast from '@/hooks/useToast';
import { Icon } from '@iconify/react/dist/iconify.js';
import { getLocalTimeZone, parseAbsoluteToLocal, parseDate, today } from '@internationalized/date';
import { Autocomplete, AutocompleteItem, Button, Checkbox, DateRangePicker, Divider, Input, Spacer, Spinner, Textarea, Tooltip } from '@nextui-org/react';
import { I18nProvider } from '@react-aria/i18n';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import { Timestamp } from 'firebase/firestore';
import AppPromoImageUpload from '@/components/AppPromoImageUpload';
import zoxsManagementService from '@/services/zoxsManagementService';

const AppPushEditor = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const isEditing = location.pathname === '/admin-push/edit';
    const [imgUrl, setImgUrl] = useState(null);

    const { showToast } = useToast();
    const [loading, setLoading] = useState(true);

    const [isSaving, setIsSaving] = useState(false);
    const [isProMode, setIsProMode] = React.useState(false);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [route, setRoute] = useState('');
    const [args, setArgs] = useState('');
    const [url, setUrl] = useState('');

    const [titleError, setTitleError] = useState(false);
    const [textError, setTextError] = useState(false);
    const [routeError, setRouteError] = useState(false);
    const [argsError, setArgsError] = useState(false);
    const [urlError, setUrlError] = useState(false);

    const [time, setTime] = React.useState(() => {
        const today = new Date();
        const start = today;
        const end = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());

        return {
            start: parseAbsoluteToLocal(start.toISOString()),
            end: parseAbsoluteToLocal(end.toISOString()),
        };
    });

    const [uploadedImage, setUploadedImage] = useState(null);

    const handleImageChange = (file) => {
        console.log('Uploaded file:', file);
        setUploadedImage(file);
    };

    useEffect(() => {
        const initialize = async () => {
            setLoading(true);
            try {
                if (isEditing && id) {
                    await fetchPush(id);
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        initialize();
    }, [isEditing, id]);

    const fetchPush = async (id) => {
        try {
            const response = await zoxsManagementService.getPushById(id);
            if (response && response.element) {
                const {
                    title,
                    text,
                    start,
                    end,
                    route,
                    args,
                    imgUrl
                } = response.element;

                setImgUrl(imgUrl);
                setTitle(title);
                setText(text);
                setTime({
                    start: parseAbsoluteToLocal(new Date(start).toISOString()),
                    end: parseAbsoluteToLocal(new Date(end).toISOString()),
                });
                setRoute(route);
                setArgs(args ? JSON.stringify(args) : '');
                setUploadedImage(imgUrl);
                setIsProMode(true);
            } else {
                showToast('Push konnte nicht geladen werden.', 'error');
            }
        } catch (error) {
            console.error('Error fetching push:', error);
            showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
        }
    };

    const generateJson = () => {
        const startDate = new Date(
            time.start.year,
            time.start.month - 1,
            time.start.day,
            time.start.hour,
            time.start.minute,
            time.start.second,
            time.start.millisecond
        );

        const endDate = new Date(
            time.end.year,
            time.end.month - 1,
            time.end.day,
            time.end.hour,
            time.end.minute,
            time.end.second,
            time.end.millisecond
        );

        const adjustedRoute = !isProMode ? '/customTab' : route || null;
        let adjustedArgs;
        if (isProMode) {
            try {
                adjustedArgs = JSON.parse(args) || { url };
            } catch (error) {
                adjustedArgs = {};
            }
        } else {
            adjustedArgs = { url };
        }

        return {
            id: id || null,
            title: title || null,
            text: text || null,
            start: startDate,
            end: endDate,
            route: adjustedRoute,
            args: JSON.stringify(adjustedArgs),
        };
    };

    const handleSubmit = async () => {
        let valid = true;

        const now = new Date();
        const startDate = new Date(Date.UTC(
            time.start.year,
            time.start.month - 1,
            time.start.day,
            time.start.hour,
            time.start.minute,
            time.start.second,
            time.start.millisecond
        ));

        const endDate = new Date(Date.UTC(
            time.end.year,
            time.end.month - 1,
            time.end.day,
            time.end.hour,
            time.end.minute,
            time.end.second,
            time.end.millisecond
        ));

        if (endDate < now || endDate < startDate) {
            showToast('Bitte wähle ein gültiges Enddatum.', 'error');
            valid = false;
        }

        if (!isProMode) {
            try {
                new URL(url);
            } catch (_) {
                setUrlError(true);
                showToast('Bitte eine gültige URL eingeben.', 'error');
                valid = false;
            }
        }

        if (isProMode) {
            if (!route) {
                setRouteError(true);
                valid = false;
            }
            try {
                const adjustedArgs = JSON.parse(args) || { url };
            } catch (error) {
                showToast('Die eingegebenen Arguments können nicht geparsed werden.', 'error');
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        const jsonBody = generateJson();

        try {
            setIsSaving(true);
            const formData = new FormData();
            formData.append("image", uploadedImage);
            Object.keys(jsonBody).forEach((key) => formData.append(key, jsonBody[key]));
            console.log(uploadedImage);
            if (isEditing) {
                await zoxsManagementService.updatePush(jsonBody);
                showToast('Push erfolgreich bearbeitet.', 'success');
                navigate('/admin-push');
            } else {
                await zoxsManagementService.createPush(formData);
                showToast('Push erfolgreich erstellt.', 'success');
                navigate('/admin-push');
            }
        } catch (error) {
            showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
        } finally {
            setIsSaving(false);
        }
    };

    const handleClose = () => {
        navigate('/admin-push');
    };

    return (
        <div className="w-full flex-1 p-4">
                <Icon
                    className="text-default-400 absolute top-4 right-4 cursor-pointer z-50"
                    icon="solar:close-square-bold"
                    width={28}
                    height={28}
                    onClick={handleClose}
                />

            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">
                    {isEditing ? 'Push bearbeiten' : 'Push erstellen'}
                </h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Erstelle oder bearbeite eine Push-Kampagne.
            </h2>

            {loading ? (
                <Spinner />
            ) : (
                <div className="mt-4 flex flex-col space-y-4">
                    <h4 className="text-medium font-medium">Push Daten</h4>
                    {isEditing ? <div className="w-full flex justify-center">
                        <Tooltip showArrow content="Bilder können nicht mehr geändert werden.">
                            <div
                                className="relative border border-gray-300 rounded-lg overflow-hidden max-w-xl"
                                style={{ aspectRatio: '16 / 9' }}
                            >
                                <img src={imgUrl} alt="Uploaded" className="w-full h-full object-cover" />
                            </div>
                        </Tooltip>
                    </div> : <AppPromoImageUpload onImageChange={handleImageChange} />}
                    <div className="flex justify-between items-center">
                        <Input
                            variant="bordered"
                            type="text"
                            size='md'
                            label="Titel"
                            value={title}
                            defaultValue=""
                            labelPlacement="inside"
                            isInvalid={titleError}
                            startContent={
                                <Icon
                                    className="text-default-400 pointer-events-none"
                                    height={18}
                                    icon="solar:text-square-outline"
                                    width={18}
                                />
                            }
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setTitleError(false);
                            }}
                        />
                        <Spacer x={2} />
                        <I18nProvider locale="de">
                            <DateRangePicker
                                label="Zeitraum"
                                value={time}
                                variant='bordered'
                                onChange={setTime}
                                visibleMonths={3}
                                startContent={
                                    <Icon
                                        className="text-default-400 pointer-events-none"
                                        height={18}
                                        icon="solar:calendar-date-outline"
                                        width={18}
                                    />
                                }
                            />
                        </I18nProvider>
                    </div>
                    <Textarea
                        variant="bordered"
                        type="text"
                        size='md'
                        label="Beschreibung"
                        value={text}
                        defaultValue=""
                        labelPlacement="inside"
                        isInvalid={textError}
                        startContent={
                            <Icon
                                className="text-default-400 pointer-events-none"
                                height={18}
                                icon="solar:document-text-outline"
                                width={18}
                            />
                        }
                        onChange={(e) => {
                            setText(e.target.value);
                            setTextError(false);
                        }}
                    />
                    <div className="flex items-center gap-4">
                        <Checkbox isSelected={isProMode} onValueChange={setIsProMode} className="flex-shrink-0">
                            Ziel manuell setzen
                        </Checkbox>
                        <div className="flex items-center w-full">
                            {!isProMode ? (
                                <Input
                                    variant="bordered"
                                    type="text"
                                    size="md"
                                    label="URL"
                                    value={url}
                                    defaultValue=""
                                    labelPlacement="inside"
                                    isInvalid={urlError}
                                    startContent={
                                        <Icon
                                            className="text-default-400 pointer-events-none"
                                            height={18}
                                            icon="solar:routing-2-outline"
                                            width={18}
                                        />
                                    }
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                        setUrlError(false);
                                    }}
                                />
                            ) : (
                                <>
                                    <Input
                                        variant="bordered"
                                        type="text"
                                        size="md"
                                        label="Route"
                                        value={route}
                                        defaultValue=""
                                        labelPlacement="inside"
                                        isInvalid={routeError}
                                        startContent={
                                            <Icon
                                                className="text-default-400 pointer-events-none"
                                                height={18}
                                                icon="solar:routing-2-outline"
                                                width={18}
                                            />
                                        }
                                        onChange={(e) => {
                                            setRoute(e.target.value);
                                            setRouteError(false);
                                        }}
                                    />
                                    <Spacer x={2} />
                                    <Input
                                        variant="bordered"
                                        type="text"
                                        size="md"
                                        label="Arguments (JSON)"
                                        value={args}
                                        defaultValue=""
                                        labelPlacement="inside"
                                        isInvalid={argsError}
                                        startContent={
                                            <Icon
                                                className="text-default-400 pointer-events-none"
                                                height={18}
                                                icon="solar:code-bold"
                                                width={18}
                                            />
                                        }
                                        onChange={(e) => {
                                            setArgs(e.target.value);
                                            setArgsError(false);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <Button
                            onPress={handleSubmit}
                            isLoading={isSaving}
                            color="primary"
                            size="lg"
                            className="py-1 px-3"
                        >
                            {isEditing ? 'Push bearbeiten' : 'Push erstellen'}
                        </Button>
                    </div>
                    <Divider />
                    <h4 className="text-medium font-medium">Dev-Details</h4>
                    <div className="flex justify-between items-center">
                        <JsonView src={generateJson()} />
                    </div>
                </div>)
            }
        </div >
    );
};

export default AppPushEditor;
