import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import AppPromotions from './AppPromotions';
import AppPromotionEditor from './AppPromotionEditor';
import AppPromotionDetail from './AppPromotionDetail';

const AppPromotionsPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  const [activeComponent, setActiveComponent] = useState('AppPromotions');
  const userHasPermission = userService.user.modules.includes(4);

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/admin-promotion/create')) {
      setActiveComponent('AppPromotionEditorCreate');
    } else if (path.startsWith('/admin-promotion/edit') && id) {
      setActiveComponent('AppPromotionEditorEdit');
    } else if (path.startsWith('/admin-promotion/') && id) {
      setActiveComponent('AppPromotionDetail');
    } else {
      setActiveComponent('AppPromotions');
    }
  }, [location.pathname, id]);

  if (!userHasPermission) {
    return <NoPermission />;
  }

  return (
    <>
      {activeComponent === 'AppPromotions' && <AppPromotions />}
      {activeComponent === 'AppPromotionEditorCreate' && <AppPromotionEditor />}
      {activeComponent === 'AppPromotionEditorEdit' && <AppPromotionEditor id={id} />}
      {activeComponent === 'AppPromotionDetail' && <AppPromotionDetail id={id} />}
    </>
  );
};

export default AppPromotionsPage;
