import userService from "../../services/userService";
import { v4 as uuidv4 } from 'uuid';

class Comment {
    constructor({
        id = uuidv4(),
        comment,
        author = userService.getUser().id,
        created = Date.now(),
        gifMedia,
        answerCount = 0,
        answers = [],
        deleted = false,
        isAnswer = false,
        reactions = [],
    }) {
        this.id = id;
        this.author = author;
        this.created = created;
        this.answerCount = answerCount;
        this.answers = answers;
        this.gifMedia = gifMedia;
        this.comment = comment;
        this.deleted = deleted;
        this.isAnswer = isAnswer;
        this.reactions = reactions;
    }

    static fromJson(json) {
        return new Comment(json);
    }
}

export default Comment;