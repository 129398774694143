import userService from "../services/userService";
import { setCookie, deleteCookie } from '../helpers/cookieHelper';
import LocalUser from "../models/user/localUser";

const baseURL = process.env.REACT_APP_BASE_URL;

const authController = {
  loginUserApi: function (user, pass, rememberUsername) {
    const data = { user, pass };

    return new Promise((resolve, reject) => {
      fetch(`${baseURL}auth/login?getUser=true`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.text().then(text => {
              resolve({ localUser: null, error: text });
            });
          }
        })
        .then(json => {
          if (json && json.token && json.user) {
            setCookie('newsappuser', json.token.token);

            const authModel = LocalUser.fromJson(json.user);
            userService.setUser(authModel);

            if (rememberUsername) {
              localStorage.setItem('rememberedUsername', user);
            } else {
              localStorage.removeItem('rememberedUsername');
            }

            resolve({ localUser: authModel, error: null });
          } else {
            resolve({ localUser: null, error: 'Invalid response from server' });
          }
        })
        .catch(error => {
          console.error(error);
          resolve({ localUser: null, error: error.toString() });
        });
    });
  },

  presetUsernameIfRemembered: function () {
    return localStorage.getItem('rememberedUsername') || '';
  },

  logoutUser: function () {
    userService.setUser(null);
    deleteCookie('newsappuser');
  }
};

export default authController;