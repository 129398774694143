import dayjs from 'dayjs';
import { getCookie } from '../helpers/cookieHelper';
import { AppUser } from '@/models/user/appUser';

class PeopleService {

    async getAllUsers() {
        try {
            const authToken = getCookie('newsappuser');

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/all`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
                    'auth-token': authToken
                }
            });

            if (response.ok) {
                const userData = await response.json();
                const users = userData.map(user => AppUser.fromJson(user));
                return users;
            } else {
                return null;
            }
        } catch (e) {
            console.error("Error fetching users:", e);
            return null;
        }
    }

    isWithinNext30Days(date) {
        if (!date) return false;
        const today = dayjs();
        const targetDate = dayjs(date).set('year', today.year());
        const difference = targetDate.diff(today, 'day');

        return difference >= 0 && difference <= 30;
    }

    categorizeUsers(users) {
        const today = dayjs();
        let newbies = [];
        let nextAnniversaries = [];
        let allUsers = [];

        users.forEach(user => {
            const dateOfEntry = user.dateOfEntry ? dayjs(user.dateOfEntry, 'YYYY-MM-DD') : null;

            if (dateOfEntry && today.diff(dateOfEntry, 'month') < 1) {
                newbies.push(user);
            }

            if (dateOfEntry && this.isWithinNext30Days(dateOfEntry)) {
                nextAnniversaries.push(user);
            }

            allUsers.push(user);
        });

        newbies = newbies.sort((a, b) => {
            const dateA = dayjs(a.dateOfEntry);
            const dateB = dayjs(b.dateOfEntry);
            return dateB - dateA;
        });

        nextAnniversaries = nextAnniversaries.sort((a, b) => {
            const anniversaryA = dayjs(a.dateOfEntry).set('year', today.year());
            const anniversaryB = dayjs(b.dateOfEntry).set('year', today.year());
            return anniversaryA - anniversaryB;
        });

        allUsers = allUsers.sort((a, b) => {
            const nameA = `${a.firstname} ${a.surname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.surname}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });

        return { newbies, nextAnniversaries, allUsers };
    }
}

const peopleService = new PeopleService();
export default peopleService;
