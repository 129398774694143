class ZoxsFeedModel {
    constructor({
        id,
        imgUrl,
        title,
        text,
        start,
        end,
        route,
        createdBy,
        updatedBy,
        created,
        updated,
        clickCount = 0,
        uniqueClickCount = 0,
        type,
        buttonColor = '#D3101E',
        buttonTextColor = '#FFFFFF',
        buttonText = 'Mehr erfahren',
        order = 10,
        args = {}
    }) {
        this.id = id;
        this.imgUrl = imgUrl;
        this.title = title;
        this.text = text;
        this.start = start;
        this.end = end;
        this.route = route;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.created = created;
        this.updated = updated;
        this.clickCount = clickCount;
        this.uniqueClickCount = uniqueClickCount;
        this.type = type;
        this.buttonColor = buttonColor;
        this.buttonTextColor = buttonTextColor;
        this.buttonText = buttonText;
        this.order = order;
        this.args = args;
    }

    static fromJson(json) {
        const convertToDate = (timestamp) => {
            if (timestamp && typeof timestamp._seconds === 'number') {
                return new Date(timestamp._seconds * 1000 + Math.floor(timestamp._nanoseconds / 1e6));
            }
            return timestamp;
        };

        return new ZoxsFeedModel({
            id: json.id,
            imgUrl: json.imgUrl,
            title: json.title,
            text: json.text,
            start: convertToDate(json.start),
            end: convertToDate(json.end),
            route: json.route,
            createdBy: json.createdBy,
            updatedBy: json.updatedBy,
            created: convertToDate(json.created),
            updated: convertToDate(json.updated),
            clickCount: json.clickCount,
            uniqueClickCount: json.uniqueClickCount,
            type: json.type,
            buttonColor: json.buttonColor,
            buttonTextColor: json.buttonTextColor,
            buttonText: json.buttonText,
            order: parseInt(json.order, 10) || json.order,
            args: json.args
        });
    }

    toJson() {
        return {
            id: this.id,
            imgUrl: this.imgUrl,
            title: this.title,
            text: this.text,
            start: this.start,
            end: this.end,
            route: this.route,
            createdBy: this.createdBy,
            updatedBy: this.updatedBy,
            created: this.created,
            updated: this.updated,
            clickCount: this.clickCount,
            uniqueClickCount: this.uniqueClickCount,
            type: this.type,
            buttonColor: this.buttonColor,
            buttonTextColor: this.buttonTextColor,
            buttonText: this.buttonText,
            order: this.order,
            args: this.args
        };
    }
}

module.exports = ZoxsFeedModel;
