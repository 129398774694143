import React, { useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Avatar, Image, Button } from "@nextui-org/react";
import { VideoPlayer } from './VideoPlayer';
import GiphyGif from './GiphyGif';
import ReactionList from './ReactionList';
import TimeAgo from 'react-timeago';
import germanStrings from 'react-timeago/lib/language-strings/de';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import GalleryComponent from './Galllery';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

const formatter = buildFormatter(germanStrings);

const PostDetail = ({ post, onOpenPopup, shadow }) => {
    const [lightboxIndex, setLightboxIndex] = useState(-1);

    const openLightbox = (index) => {
        setLightboxIndex(index);
    };

    const closeLightbox = () => {
        setLightboxIndex(-1);
    };

    const galleryImages = post.imageMedia.map(image => ({ src: image, width: 4, height: 3 }));

    let htmlContent = '';
    if (post.textContent) {
        try {
            const deltaOps = JSON.parse(post.textContent);
            const converter = new QuillDeltaToHtmlConverter(deltaOps, {
                inlineStyles: {
                    color: (value) => {
                        if (!value) {
                            return 'color: var(--nextui-colors-default-foreground);';
                        }
                        if (value === '#f00') {
                            return 'color: var(--nextui-colors-primary);';
                        }
                        return `color: ${value};`;
                    },
                },
            });
            htmlContent = converter.convert();
        } catch (error) {
            console.log('Post without valid text content', error);
        }
    }

    return (
        <Card className="w-full rounded-lg border bg-background shadow">
            <CardHeader className="justify-between">
                <div className="flex gap-4">
                    <Avatar isBordered radius="full" size="md" src={post.user.avatarUrl} />
                    <div className="flex flex-col gap-1 items-start justify-center">
                        <h4 className="text-small font-semibold leading-none text-default-600">{post.user.firstname} {post.user.surname}</h4>
                        <h5 className="text-small tracking-tight text-default-400">
                            <TimeAgo date={post.created} formatter={formatter} />
                        </h5>
                    </div>
                </div>
            </CardHeader>
            <CardBody className="flex flex-col md:flex-row px-0 py-0 text-small text-default-400">
                <div className="md:flex-1">
                    {htmlContent && (
                        <div
                            className="zx-quill-content m-4"
                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                        />
                    )}

                    {post.videoMedia && post.videoMedia.length > 0 && (
                        <div className="mr-3 ml-3 mb-3 rounded-xl overflow-hidden">
                            {post.videoMedia.map((video, index) => (
                                <VideoPlayer key={index} videoFile={video} />
                            ))}
                        </div>
                    )}

                    {post.imageMedia && post.imageMedia.length > 0 && (
                        <div>
                            {post.imageMedia.length === 1 ? (
                                <div className="px-3" onClick={() => openLightbox(0)}>
                                    <Image isZoomed className="object-cover w-full h-auto" alt="Beitragsbild" src={post.imageMedia[0]} />
                                </div>
                            ) : (
                                <div className="px-3 overflow-x-auto w-full">
                                    <div className="flex flex-nowrap space-x-3">
                                        {post.imageMedia.map((image, index) => (
                                            <div
                                                className="w-[200px] h-[200px] overflow-hidden flex-shrink-0 mb-1 rounded-xl"
                                                key={index}
                                                onClick={() => openLightbox(index)}
                                            >
                                                <Image isZoomed
                                                    className="object-cover w-[200px] h-[200px]"
                                                    alt="Beitragsbild"
                                                    src={image}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {post.gifMedia && post.gifMedia[0] && post.gifMedia[0].id && (
                        <div className="px-3 w-full">
                            <GiphyGif gifId={post.gifMedia[0].id}></GiphyGif>
                        </div>
                    )}
                </div>

                <div className="md:w-px bg-default-300 hidden md:block mx-4"></div>

                <div className="md:flex-1">
                </div>
            </CardBody>
            <CardFooter className="gap-3">
                <div className="flex gap-1">
                    <ReactionList reactions={post.reactions}></ReactionList>
                </div>
                <Button onClick={onOpenPopup} className="bg-white">
                    <div className="flex gap-1">
                        <p className="font-semibold text-default-400 text-small">{post.commentCount}</p>
                        <p className="text-default-400 text-small">Kommentare</p>
                    </div>
                </Button>
            </CardFooter>

            {lightboxIndex >= 0 && (
                <GalleryComponent
                    images={galleryImages}
                    initialIndex={lightboxIndex}
                    onClose={closeLightbox}
                />
            )}
        </Card>
    );
};

export default PostDetail;
