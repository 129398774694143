import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

const AppFeedDetail = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  return (
    <div>
      <h2>App Feed Detail</h2>
      {id ? (
        <p>Displaying details for app feed ID: {id}</p>
      ) : (
        <p>No app feed ID provided.</p>
      )}
    </div>
  );
};

export default AppFeedDetail;
