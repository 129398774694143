import { Input } from "@nextui-org/react";

const OrderItemComponent = ({ offerId, numberKey }) => {
    return (
        <div className="flex gap-2 mt-2" id={offerId + '' + numberKey} numberKey={numberKey}>
            <Input
                type="text"
                label="🛒 Bestellung"
                variant="bordered"
                name={`orderItems[${numberKey}][name]`}
                className="w-full"
            />
            <Input
                type="number"
                name={`orderItems[${numberKey}][price]`}
                label="🪙 Preis"
                step="0.01"
                variant="bordered"
                className="w-48"
            />
        </div>
    )
}

export default OrderItemComponent;