import { collection, doc, query, orderBy, getDocs, updateDoc, } from 'firebase/firestore';
import db from '../firebaseConfig';
import Comment from '../models/social/comment';
import { FeedPost } from '../models/social/feedPost';
import { getCookie } from '../helpers/cookieHelper';
import { AppUser } from '@/models/user/appUser';

class FeedService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.bearerToken = process.env.REACT_APP_BEARER_TOKEN;
  }

  async getPosts(page = 0, size = 10) {
    try {
      const authToken = getCookie('newsappuser');

      const response = await fetch(`${this.baseUrl}feed?page=${page}&size=${size}&withUsers=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching posts: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of posts');
      }

      const posts = data.map((postData) => FeedPost.fromJson(postData));

      return { postsData: posts };
    } catch (error) {
      console.error('Error fetching posts:', error.message);
      throw error;
    }
  }

  async getFilteredPosts(filters, page = 1, size = 10) {
    try {
      const authToken = getCookie('newsappuser');
  
      const {
        maxDate,
        minDate,
        locations = [],
        departments = [],
        groups = [],
        authors = [],
        mediaTypes = [],
        commentedByMe = false,
        sortDirection = 'desc',
      } = filters;
  
      const response = await fetch(`${this.baseUrl}feed/filtered?withUsers=true&page=${page}&size=${size}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify({
          maxDate,
          minDate,
          locations,
          departments,
          groups,
          authorIds: authors,
          mediaTypes,
          commentedByMe,
          sortDirection,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching filtered posts: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of posts');
      }
  
      const posts = data.map((postData) => FeedPost.fromJson(postData));
  
      console.log(posts);
  
      return { postsData: posts };
    } catch (error) {
      console.error('Error fetching filtered posts:', error.message);
      throw error;
    }
  }  

  async getCommentsForPost(postId) {
    try {
      const commentsCollection = collection(db, 'posts', postId, 'comments');
      const commentsQuery = query(commentsCollection, orderBy('created', 'asc'));

      const querySnapshot = await getDocs(commentsQuery);

      const comments = [];
      querySnapshot.forEach((doc) => {
        const commentData = { id: doc.id, ...doc.data() };
        comments.push(Comment.fromJson(commentData));
      });

      return comments;
    } catch (error) {
      console.error('Error fetching comments for post:', error);
      throw error;
    }
  }

  async updatePost(id, field, value) {
    const postRef = doc(db, 'posts', id);
    return updateDoc(postRef, { [field]: value });
  }

  async getFeedUsers() {
    try {
      const authToken = getCookie('newsappuser');

      const response = await fetch(`${this.baseUrl}feed/u`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching feed users: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of posts');
      }

      const users = data.map((userData) => AppUser.fromJson(userData.user));

      return { users: users };
    } catch (error) {
      console.error('Error fetching posts:', error.message);
      throw error;
    }
  }

}

const feedService = new FeedService();
export default feedService;
