class VideoFile {
    constructor({ url, thumbnail, duration, aspectRatio }) {
        this.url = url;
        this.thumbnail = thumbnail;
        this.duration = duration;
        this.aspectRatio = aspectRatio;
    }

    static fromJson(json) {
        return new VideoFile(json);
    }

    toJson() {
        return {
            url: this.url,
            thumbnail: this.thumbnail,
            duration: this.duration,
            aspectRatio: this.aspectRatio,
        };
    }
}

export { VideoFile };