"use client";

import React from "react";
import { Tab, Tabs, } from "@nextui-org/react";
import ProfileSettings from "./ProfileSettings";
import PushSettings from "./PushSettings";
import AppSettings from "./AppSettings";
import CompanySettings from "./CompanySettings";
import userService from "@/services/userService";

export default function Settings() {
  const userModules = userService.user.modules;

  return (
    <div className="w-full h-full flex-1 p-4" style={{ overflowY: 'auto' }}>
      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">Einstellungen</h1>
      </div>
      <h2 className="mt-2 text-small text-default-500">
        Richte hier Dein Profil ein und verwalte Benachrichtungseinstellungen.
      </h2>
      <Tabs
        fullWidth
        radius="full"
        classNames={{
          base: "mt-6",
          cursor: "bg-content1 dark:bg-content1",
          panel: "w-full p-0 pt-4",
        }}
      >
        {userModules.includes(6) && (
          <Tab key="company" title="Unternehmen">
            <CompanySettings />
          </Tab>
        )}
        <Tab key="profile" title="Profil">
          <ProfileSettings />
        </Tab>
        <Tab key="push" title="Benachrichtigungen">
          <PushSettings />
        </Tab>
        <Tab key="app" title="Web-App">
          <AppSettings />
        </Tab>
      </Tabs>
    </div>
  );
}