"use client";

import React from "react";
import { Spacer } from "@nextui-org/react";
import { cn } from "@nextui-org/react";

const AppSettings = React.forwardRef(({ className, ...props }, ref) => {

  return (
    <div ref={ref} className={cn("p-2", className)} {...props}>
      <div>
        <p className="text-base font-medium text-default-700">Login</p>
        <p className="mt-1 text-sm font-normal text-default-400">
          Wie kommst Du komfortabel in die Newsapp rein?
        </p>
      </div>
      <Spacer y={4} />
      <div>
        <p className="text-base font-medium text-default-700">Versionsdaten</p>
        <p className="mt-1 text-sm font-normal text-default-400">Aktuelle Infos zu diesem Release.</p>
      </div>
    </div>
  );
});

AppSettings.displayName = "AppSettings";

export default AppSettings;
