import { getCookie } from '../helpers/cookieHelper';
import Wiki from '../models/wiki/wiki';

class WikiService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.bearerToken = process.env.REACT_APP_BEARER_TOKEN;
  }

  async getWikiCollections() {
    try {
      const authToken = getCookie('newsappuser');

      const response = await fetch(`${this.baseUrl}wiki`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching posts: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of wikis');
      }

      const wikis = data.map((wikiData) => Wiki.fromJson(wikiData));

      return { wikiData: wikis };
    } catch (error) {
      console.error('Error fetching wikis:', error.message);
      throw error;
    }
  }
}

const wikiService = new WikiService();
export default wikiService;
