import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import AppPushs from './AppPushs';
import AppPushEditor from './AppPushEditor';
import AppPushDetail from './AppPushDetail';

const AppPushPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  const [activeComponent, setActiveComponent] = useState('AppPushs');
  const userHasPermission = userService.user.modules.includes(8);

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/admin-push/create')) {
      setActiveComponent('AppPushEditorCreate');
    } else if (path.startsWith('/admin-push/edit') && id) {
      setActiveComponent('AppPushEditorEdit');
    } else if (path.startsWith('/admin-push/') && id) {
      setActiveComponent('AppPushDetail');
    } else {
      setActiveComponent('AppPushs');
    }
  }, [location.pathname, id]);

  if (!userHasPermission) {
    return <NoPermission />;
  }

  return (
    <>
      {activeComponent === 'AppPushs' && <AppPushs />}
      {activeComponent === 'AppPushEditorCreate' && <AppPushEditor />}
      {activeComponent === 'AppPushEditorEdit' && <AppPushEditor id={id} />}
      {activeComponent === 'AppPushDetail' && <AppPushDetail id={id} />}
    </>
  );
};

export default AppPushPage;
