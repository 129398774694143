import React, { useEffect, useState, useMemo, useCallback } from 'react';
import peopleService from '../services/peopleService';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  User,
  Spinner,
  Spacer,
  Link,
} from "@nextui-org/react";
import Location from '../enums/location';
import Department from "../enums/department";
import AgeHelper from '../helpers/getAgeHelper';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import TimeAgo from 'react-timeago'
import germanStrings from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import userService from '../services/userService';
const formatter = buildFormatter(germanStrings)
dayjs.locale('de');

const initial_columns_users = ["person", "dateOfEntry", "mail",];
const initial_columns_theme = ["person", "dateOfEntry",];

const checkProfilePicture = async (id) => {
  const fileExtensions = ['png', 'jpg'];
  for (const ext of fileExtensions) {
    const filePath = `internal/users/${id}/public/${id}.${ext}`;
    const storage = getStorage();
    const fileRef = ref(storage, filePath);
    try {
      const downloadUrl = await getDownloadURL(fileRef);
      return downloadUrl;
    } catch (error) {
    }
  }
  return null;
};

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('de-DE', options);
};

const adaptUserData = async (user) => {
  const profilePictureUrl = await checkProfilePicture(user.id);

  return {
    id: user.id || Math.random().toString(36).substr(2, 9),
    name: user.name,
    isHeadOf: user.isHeadOf,
    dateOfBirth: user.dateOfBirth,
    dateOfEntry: user.dateOfEntry,
    location: user.location && Location[user.location]
      ? Location[user.location].locationName
      : 'Unbekannter Ort',
    department: user.department && Department[user.department]
      ? Department[user.department].departmentName
      : 'Unbekannte Abteilung',
    mail: user.mail,
    phone: user.phone ? user.phone : null,
    latestRefresh: user.latestRefresh,
    profilePictureUrl: profilePictureUrl,
  };
};

const People = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [newbies, setNewbies] = useState([]);
  const [nextAnniversaries, setNextAnniversaries] = useState([]);

  const [departmentFilter, setDepartmentFilter] = useState(new Set(["all"]));
  const [locationFilter, setLocationFilter] = useState(new Set(["all"]));
  const [visibleColumns, setVisibleColumns] = useState(new Set(initial_columns_users));
  const [visibleColumnsTheme] = useState(new Set(initial_columns_theme));
  const [columns, setColumns] = useState([
    { name: "Person", uid: "person" },
    { name: "Einstiegsdatum", uid: "dateOfEntry", sortable: true },
    { name: "E-Mail", uid: "mail" },
    { name: "Telefon", uid: "phone" },
    { name: "Zuletzt eingeloggt", uid: "latestRefresh", sortable: true },
  ]);

  useEffect(() => {
    const fetchModules = async () => {
      const user = await userService.getUser();
      if (user.modules.includes(6)) {
        setColumns(prevColumns => [
          { name: "ID", uid: "id", sortable: true },
          { name: "Person", uid: "person" },
          { name: "Geburtstag", uid: "dateOfBirth" },
          { name: "Einstiegsdatum", uid: "dateOfEntry", sortable: true },
          { name: "E-Mail", uid: "mail" },
          { name: "Telefon", uid: "phone" },
          { name: "Zuletzt eingeloggt", uid: "latestRefresh", sortable: true },
          { name: "Optionen", uid: "actions" },
        ]);
      }
    };

    fetchModules();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const fetchedUsers = await peopleService.getAllUsers();
        const categorizedUsers = await peopleService.categorizeUsers(fetchedUsers);

        const allUsers = await Promise.all(
          categorizedUsers.allUsers.map(user => adaptUserData(user))
        );
        const newbies = await Promise.all(
          categorizedUsers.newbies.map(user => adaptUserData(user))
        );
        const nextAnniversaries = await Promise.all(
          categorizedUsers.nextAnniversaries.map(user => adaptUserData(user))
        );

        setUsers(allUsers);
        setNewbies(newbies);
        setNextAnniversaries(nextAnniversaries);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "person",
    direction: "ascending",
  });

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;
    return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
  }, [visibleColumns, columns]);

  const headerColumnsTheme = React.useMemo(() => {
    if (visibleColumnsTheme === "all") return columns;
    return columns.filter((column) => Array.from(visibleColumnsTheme).includes(column.uid));
  }, [visibleColumnsTheme, columns]);

  const filteredItems = useMemo(() => {
    let filteredUsers = [...users];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    const departmentArray = Array.from(departmentFilter);

    if (departmentFilter.has("all") && departmentArray[departmentArray.length - 1] !== "all") {
      departmentFilter.delete("all");
    }

    if (departmentFilter.size === Object.keys(Department).length) {
      departmentFilter.clear();
      departmentFilter.add("all");
    }

    if (!departmentFilter.has("all") && departmentFilter.size > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(departmentFilter).some(department =>
          user.department === department
        )
      );
    }

    if (departmentFilter.has('all')) {
      departmentFilter.clear();
      departmentFilter.add("all");
    }



    const locationArray = Array.from(locationFilter);

    if (locationFilter.has("all") && locationArray[locationArray.length - 1] !== "all") {
      locationFilter.delete("all");
    }

    if (locationFilter.size === Object.keys(Location).length) {
      locationFilter.clear();
      locationFilter.add("all");
    }

    if (!locationFilter.has("all") && locationFilter.size > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(locationFilter).some(location =>
          user.location === location
        )
      );
    }

    if (locationFilter.has('all')) {
      locationFilter.clear();
      locationFilter.add("all");
    }

    return filteredUsers;
  }, [users, filterValue, departmentFilter, locationFilter, hasSearchFilter]);

  const items = React.useMemo(() => {
    return filteredItems;
  }, [filteredItems]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = useCallback((user, columnKey) => {
    const cellValue = user[columnKey];
    switch (columnKey) {
      case "person":
        return (
          <User
            avatarProps={{ src: user.profilePictureUrl, name: user.name, }}
            description={`${user.isHeadOf ? 'Head Of ' : ''}${user.department}, ${user.location}`}
            name={user.name}
          >
            {user.mail}
          </User>
        );
      case "dateOfBirth":
        return user.dateOfBirth
          ? `${formatDate(user.dateOfBirth)} (${AgeHelper.get(user.dateOfBirth)} Jahre)`
          : null;
      case "dateOfEntry":
        return user.dateOfEntry
          ? formatDate(user.dateOfEntry)
          : null;
      case "mail":
        return <Link href={`mailto:${user.mail}`}>{user.mail}</Link>;
      case "phone":
        return <Link href={`tel:${user.phone}`}>{user.phone}</Link>;
      case "latestRefresh":
        return user.latestRefresh
          ? <TimeAgo date={user.latestRefresh} formatter={formatter} />
          : null;
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown className="w-[250px]">
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <i className="fa-light fa-ellipsis-vertical text-default-300" style={{ textAlign: 'center' }}></i>
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  description="Unsichtbar schalten"
                  startContent={<i className="fa-light fa-ban" style={{ width: '18px', textAlign: 'center' }}></i>}
                >
                  Deaktivieren
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);


  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);

  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Suche nach Namen..."
            startContent={<i className="fa-light fa-magnifying-glass" style={{ width: '18px', textAlign: 'center' }}></i>}
            value={filterValue}
            onClear={onClear}
            onValueChange={onSearchChange}
          />
          <div className="flex gap-3">
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<i className="fa-light fa-chevron-down text-small" style={{ textAlign: 'center' }}></i>} variant="flat">
                  Abteilungen
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Abteilungen"
                closeOnSelect={false}
                selectedKeys={departmentFilter}
                selectionMode="multiple"
                onSelectionChange={setDepartmentFilter}
              >
                <DropdownItem key="all">Alle Abteilungen</DropdownItem>
                {Object.keys(Department).map((deptKey) => (
                  <DropdownItem key={Department[deptKey].departmentName}>
                    {Department[deptKey].departmentName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<i className="fa-light fa-chevron-down text-small" style={{ textAlign: 'center' }}></i>} variant="flat">
                  Standorte
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Standorte"
                closeOnSelect={false}
                selectedKeys={locationFilter}
                selectionMode="multiple"
                onSelectionChange={setLocationFilter}
              >
                <DropdownItem key="all">Alle Standorte</DropdownItem>
                {Object.keys(Location).map((locKey) => (
                  <DropdownItem key={Location[locKey].locationName}>
                    {Location[locKey].locationName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<i className="fa-light fa-chevron-down text-small" style={{ textAlign: 'center' }}></i>} variant="flat">
                  Spalten
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid}>
                    {column.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="flex justify-between items-center pt-2">
          <span className="text-default-400 text-small">Insgesamt {users.length} Nutzer</span>
        </div>
      </div>
    );
  }, [
    filterValue,
    departmentFilter,
    locationFilter,
    visibleColumns,
    users.length,
    onSearchChange,
    onClear,
    columns,
  ]);

  return (
    <div className="w-full flex-1 p-4">
      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">Team</h1>
      </div>
      <h2 className="mt-2 text-small text-default-500 mb-6">
        Lerne Deine Teamkollegen kennen - Wer arbeitet wo?
      </h2>
      <p className="text-base font-medium text-default-700">Newbies</p>
      <p className="mt-1 text-sm font-normal text-default-400 mb-4">
        Neue Kollegen, die in den letzten 30 Tagen angefangen haben.
      </p>
      {loading ? (
        <>
          <Spinner />
          <Spacer y={1} />
        </>
      ) : (
        <>
          <Table
            aria-label="Newbies"
            isHeaderSticky
            bottomContentPlacement="outside"
            removeWrapper
            topContentPlacement="outside"
          >
            <TableHeader columns={headerColumnsTheme}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                >
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody emptyContent={"Keine Nutzer gefunden"} items={newbies}>
              {(item) => (
                <TableRow key={item.id}>
                  {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Spacer y={1} />
        </>
      )}
      <Spacer y={4} />
      <p className="text-base font-medium text-default-700">Jubiläen</p>
      <p className="mt-1 text-sm font-normal text-default-400 mb-4">
        Kollegen, die in den nächsten 30 Tagen ihren ZOXS-Geburtstag feiern.
      </p>
      {loading ? (
        <>
          <Spinner />
          <Spacer y={2} />
        </>
      ) : (
        <>
          <Table
            aria-label="Anniversaries"
            isHeaderSticky
            bottomContentPlacement="outside"
            removeWrapper
            topContentPlacement="outside"
          >
            <TableHeader columns={headerColumnsTheme}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                >
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody emptyContent={"Keine Nutzer gefunden"} items={nextAnniversaries}>
              {(item) => (
                <TableRow key={item.id}>
                  {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Spacer y={1} />
        </>
      )}
      <Spacer y={4} />
      <p className="text-base font-medium text-default-700">Alle Personen</p>
      <p className="mt-1 text-sm font-normal text-default-400 mb-4">
        Bitte beachte, dass Dir hier nur Mitglieder angezeigt werden, die sich mindestens 1x eingeloggt haben.
      </p>
      {loading ? (
        <>
          <Spinner />
          <Spacer y={1} />
        </>
      ) : (
        <>
          <Table
            aria-label="Users"
            isHeaderSticky
            bottomContentPlacement="outside"
            selectedKeys={selectedKeys}
            sortDescriptor={sortDescriptor}
            removeWrapper
            topContent={topContent}
            topContentPlacement="outside"
            onSelectionChange={setSelectedKeys}
            onSortChange={setSortDescriptor}
          >
            <TableHeader columns={headerColumns}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                  allowsSorting={column.sortable}
                >
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody emptyContent={"Keine Nutzer gefunden"} items={sortedItems}>
              {(item) => (
                <TableRow key={item.id}>
                  {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Spacer y={1} />
        </>
      )}
    </div>
  );
};

export default People;
